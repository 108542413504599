<template>
  <el-tooltip content="Complete this job in task"
    :open-delay="300" placement="top">
    <div v-if="!this.$store.getters.getUserExtraInfo?.hide_generic_task_assignment ?? false">
      <base-button :disabled="!isLinkATaskEnabled" id="btnCompleteTask" class="btn-simple mr-2"
        @click="updateStatus()">
        {{ $t("taskAssignmentGeneric.documents.addDocument.completeTask") }}
      </base-button>
    </div>
  </el-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
import { completeThisJob } from "@/util/utils";

export default {
  name: 'TaskAssignmentCompleteTask',
  data() {
    return {};
  },
  computed: {
    ...mapGetters("taskAssignmentGeneric", ["isLinkATaskEnabled", "getTaskList", "isTaskSelected"]),
    ...mapGetters({
      order: "getOrder",
    }),
  },
  methods: {
    async updateStatus() {
      try {
        let selectedTask = this.isTaskSelected.map(({id}) => id);
        let isReviewer = this.isTaskSelected.map(({reviewer_id}) => reviewer_id)
        let status = '';

        if(isReviewer[0] !== null) {
          status = "waiting"
        }else {
          status = "approved"
        }
        this.$preloader.fadeIn();

        await this.$axios.post(
          "/features/tasks/order",
          {
            order_status: status,
            task_id: selectedTask[0],
            order_detail_id: this.order.id,
            approve_date: new Date()
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }

        );

        this.$preloader.fadeOut();
        completeThisJob("completeJob");
        window.gtm.create_task({
            tool_name: this.order.slug,
            tool_category: this.order.category,
            order_id: this.order.id,
            order_detail_id: this.order.id,
            user_id: this.getUser.id,
            email: this.getUser.email,
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
}
</script>
