<template>
  <VueEditor
    ref="editor"
    :editorOptions="editorOptions"
    v-model="inputVal"
    @selection-change="onSelectionChange"
    class="richText"
  >
    <template #toolbar>
      <div class="secondary-toolbar"></div>

      <modal
        :show="showAiEditPromptModal"
        showClose
        :showCloseOnTop="false"
        modalClasses="free-prompt-edit-modal"
        modalContentClasses="free-prompt-edit-modal-content"
        @close="closeAiEditPromptModal"
      >
        <template #header>
          <h4 class="card-title">Refine with AI</h4>
        </template>

        <div
          v-if="aiEditResultPreview"
          v-html="aiEditResultPreview"
          disabled
          class="d-block border rounded text-dark py-1 px-2 mb-2 w-100"
          style="height: 200px"
        ></div>

        <div class="d-flex flex-row justify-between align-items-center">
          <BaseInput
            type="text"
            placeholder="E.g. Improve writing"
            ref="aiEditPrompt"
            v-model="aiEditPrompt"
            class="mb-0 mr-2 w-100"
          />

          <base-button
            :disabled="aiEditPrompt == '' || loadingAiEditSubmitButton"
            :class="[
              'text-nowrap',
              !aiEditResultPreview
                ? 'btn-refine-generate'
                : 'btn-refine-rigenerate',
            ]"
            @click="requestAIEdit"
          >
            <span v-if="!loadingAiEditSubmitButton">
              <span v-if="!aiEditResultPreview"> Generate </span>
              <span v-else>
                <i class="fa-solid mr-1 fa-rotate"></i>
                Rigenerate
              </span>
            </span>
            <span v-else>
              <i class="fa-solid mr-1 fa-spinner fa-spin"></i>
            </span>
          </base-button>

          <base-button
            class="ml-2 btn-refine-insert"
            v-if="aiEditResultPreview"
            simple
            @click="replaceEditedText"
          >
            Paste to your document
          </base-button>
        </div>
      </modal>
    </template>
  </VueEditor>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Delta from "quill-delta";

import baseToolbar from "./RichTextEditor/config/toolbar";

import Modal from "../Modal.vue";
import BaseInput from "./BaseInput.vue";
import BaseButton from "../BaseButton.vue";

import { mapGetters } from "vuex";
import { apiHandler } from "@/util/errorHandling";
import axios from "@/axios";

export default {
  components: {
    VueEditor,
    [Modal.name]: Modal,
    BaseInput,
    BaseButton,
  },

  data() {
    return {
      quill: null,
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [...baseToolbar],
          clipboard: true,
        },
      },
      aiEditPrompt: "",
      aiEditResultPreview: "",
      showAiEditPromptModal: false,
      loadingAiEditSubmitButton: false,
    };
  },
  props: {
    value: {
      required: false,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    currentSelection() {
      return this.quill.getSelection(true);
    },
    ...mapGetters({
      user: "getUser",
    }),
    getInfoTool() {
      return this.$store.getters.getTools.find(
        (tool) => tool.slug === this.$route.params.slug,
      );
    },
  },
  mounted() {
    this.quill = this.$refs.editor.quill;

    this.$root.$on(
      "rich-text-editor:open-prompt-modal",
      this.openAiEditPromptModal,
    );
  },

  methods: {
    onSelectionChange(event) {
      this.$root.$emit(
        "rich-text-editor:prompt-modal-enabled",
        event?.length > 0,
      );
    },
    openAiEditPromptModal() {
      const selection = this.currentSelection;

      if (selection?.length > 0) {
        this.showAiEditPromptModal = true;
        this.$nextTick(() => this.$refs.aiEditPrompt.focus());
      }
    },
    async requestAIEdit() {
      const selection = this.currentSelection;

      if (selection?.length > 0) {
        const fullText = this.inputVal;
        const selectedText = this.quill.getText(
          selection.index,
          selection.length,
        );
        const changeFrom = fullText.indexOf(selectedText);
        const changeTo = changeFrom + selectedText.length;

        if (changeFrom < 0) {
          this.$message.error(
            "Invalid selection. Please only select one title/paragraph at a time",
          );
          return;
        }

        this.loadingAiEditSubmitButton = true;
        await apiHandler(async () => {
          const response = await axios.post(
            "tools/free_prompt_edit/update_text",
            {
              user_id: this.user.id,
              full_text: fullText,
              prompt: this.aiEditPrompt,
              change_from: changeFrom,
              change_to: changeTo,
            },
          );

          const data = response.data.data;
          this.aiEditResultPreview = data.updated_text;

          this.loadingAiEditSubmitButton = false;
          window.gtm.refine_with_ai_used({
            tool_name: `${this.getInfoTool.product_keys[0].gtag_service_group} - ${this.getInfoTool.product_keys[0].gtag_service_name}`,
            tool_category: this.getInfoTool.product_keys[0].gtag_service_group,
            user_id: this.user.id,
            email: this.user.email,
          });
          return response;
        });
      }
    },
    replaceEditedText() {
      const selection = this.currentSelection;
      const updatedTextInsertOperation = this.quill.clipboard.convert(
        this.aiEditResultPreview,
      );

      this.quill.updateContents(
        new Delta()
          .retain(selection.index)
          .delete(selection.length)
          .concat(updatedTextInsertOperation),
      );

      this.closeAiEditPromptModal();
    },
    closeAiEditPromptModal() {
      this.showAiEditPromptModal = false;
      this.reset();
    },
    reset() {
      this.aiEditPrompt = "";
      this.aiEditResultPreview = "";
      this.loadingAiEditSubmitButton = false;
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  background-color: transparent !important;
  overflow: hidden;
  inset: 0 !important;
  position: fixed !important;
}

.btn-refine-generate {
  font-size: 12px;
  font-weight: bold;
  width: 80px;
  padding-left: 14px;
  padding-right: 14px;

  &:disabled {
    background: #f2f4f6 !important;
    color: #cccccc !important;
  }
}

.btn-refine-rigenerate {
  font-size: 12px;
  font-weight: bold;
  width: 160px;
  padding-left: 10px;
  padding-right: 10px;

  &:disabled {
    background: #f2f4f6 !important;
    color: #cccccc !important;
  }
}

.btn-refine-insert {
  font-size: 12px;
  font-weight: bold;
  width: 220px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
