<template>
  <div class="row">
    <div class="col-12">
      <PopupInput @change-value="setEnableMetaData"
        :switchmetadata="this.order.json_data?.toggle_metadata"
        title="Manage meta data">
        <!-- Json Data -->
         <div v-if="order.json_data.api_input.page_url[0].page_title || order.json_data.api_input.page_url[0].page_description">
          <div v-if="!order.json_data.api_input.page_url[0].page_title && !order.json_data.api_input.page_url[0].page_description">
            <span>No meta title or meta description available. Click 'Generate' to add a new one, or choose a primary keyword if desired.""</span>
          </div>
          <div v-else>
            <div v-if="!order.json_data.api_input.page_url[0].page_title">
              <span>No meta title available. Click 'Generate' to add a new one, or choose a primary keyword if desired."</span>
            </div>
            <div v-if="!order.json_data.api_input.page_url[0].page_description">
              <span>No meta description available. Click 'Generate' to add a new one, or choose a primary keyword if desired."</span>
            </div>
          </div>
         </div>
         <!-- Page Url -->
         <div v-else="task?.page_url?.page_title || task?.page_url?.page_description">
          <div v-if="!task?.page_url?.page_title && !task?.page_url?.page_description">
            <span>No meta title or meta description available. Click 'Generate' to add a new one, or choose a primary keyword if desired.""</span>
          </div>
          <div v-else>
            <div v-if="!task?.page_url?.page_title">
              <span>No meta title available. Click 'Generate' to add a new one, or choose a primary keyword if desired."</span>
            </div>
            <div v-if="!task?.page_url?.page_description">
              <span>No meta description available. Click 'Generate' to add a new one, or choose a primary keyword if desired."</span>
            </div>
          </div>
         </div>
        <div class="pop-input-content mt-3">
          <div v-if="order.json_data.api_input.page_url[0].page_title">
            <label class="mb-2">Meta title</label>
            <BaseInput
              type="text"
              :placeholder="order ? order.json_data.api_input.page_url[0].page_title : 'e.g. Delicious & Nutritious Recipe - Healthy, Easy, & Balanced'"
              class="mb-2"
              v-model="form.meta_title_description.meta_title"
            />
          </div>
          <div v-else="task?.page_url?.page_title">
            <label v-if = task.page_url?.page_title class="mb-2">Meta title</label>
            <BaseInput
              v-if = task.page_url?.page_title
              type="text"
              :placeholder="task ? task?.page_url?.page_title : 'e.g. Delicious & Nutritious Recipe - Healthy, Easy, & Balanced'"
              class="mb-2"
              v-model="form.meta_title_description.meta_title"
            />
          </div>
          <div v-if="order.json_data.api_input.page_url[0].page_description">
            <label class="mb-2">Meta description</label>
            <BaseInput
              type="text"
              :placeholder="order ? order.json_data.api_input.page_url[0].page_description : 'e.g. Discover how to prepare a [Meal Name] that\'s healthy, delicious, and easy to make. Packed with nutrients and perfect for a balanced diet, this recipe is ideal for anyone looking to eat well.'"
              class="mb-2"
              v-model="form.meta_title_description.meta_description"
            />
          </div>
          <div v-else="task?.page_url?.page_description">
            <label v-if = task?.page_url?.page_description class="mb-2">Meta description</label>
            <BaseInput
              v-if = task?.page_url?.page_description
              type="text"
              :placeholder="task ? task?.page_url?.page_description : 'e.g. Delicious & Nutritious Recipe - Healthy, Easy, & Balanced'"
              class="mb-2"
              v-model="form.meta_title_description.meta_description"
            />
          </div>
          <label class="mb-2">Primary keyword<span class="optional">(Optional)</span></label>
          <BaseInput
            type="text"
            :placeholder="'e.g. healthy meal'"
            class="m-0"
            v-model="form.primary_kw"
          />
          <div class="text-right mt-3">
            <base-button class="btn-simple" @click.prevent="createTaskMetaData" native-type="submit" :disabled="!order.json_data.api_input.page_url[0]">
              Generate new
            </base-button>
            <base-button class="btn-simple ml-3" @click.prevent="optimizeTaskMetaData" native-type="submit" :disabled="!order.json_data.api_input.page_url[0].page_title || !order.json_data.api_input.page_url[0].page_description">
              Optimize existing
            </base-button>
            <base-button class="btn btn__generate ml-3" @click.prevent="saveTaskMetaData" native-type="submit" :disabled="!order.json_data.api_input.page_url[0]">
              Save
            </base-button>
          </div>
        </div>
      </PopupInput>
    </div>
  </div>
</template>

<script>
import PopupInput from "src/pages/Tools/BlogPostParagraphs/PopupInput.vue";
import { BaseInput } from "@/components";

export default {
  name: "TaskAssignmentMetaData",
  components: {
    PopupInput,
    BaseInput,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    history: {
      type: Array,
      required: false,
    },
    order: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        footer_content: this.order?.content,
        brand: this.task.page_url.brand_voice?.name,
        category_name: this.order.json_data.api_input.category_name,
        primary_kw: "",
        order_detail_id: this.order?.order_detail_id,
        meta_title_description: {
          meta_title: this.order.json_data.api_input.page_url[0].page_title.trim(),
          meta_description: this.order.json_data.api_input.page_url[0].page_description.trim(),
        },
      },
    };
  },
  watch: {
    order(setForm) {
      console.log('watch', setForm)
      this.form.meta_title_description = {
        meta_title: setForm.json_data.api_input.page_url[0].page_title.trim(),
        meta_description: setForm.json_data.api_input.page_url[0].page_description.trim(),
      };
      this.form.brand = setForm.json_data.api_input?.page_url[0].brand_voice?.name;
      this.form.category_name = setForm.json_data.api_input?.category_name;
      this.form.order_detail_id = setForm.order_detail_id;
      this.form.footer_content = setForm.content;
    },
  },
  methods: {
    async createTaskMetaData() {
      try {
        this.$preloader.fadeIn();
        const updatedOrder = await this.$store.dispatch("createTaskMetaData", this.form);
        if (updatedOrder && updatedOrder.taskMetaData && updatedOrder.taskMetaData.api_output && updatedOrder.taskMetaData.api_output.new_meta_title_description) {
          const newMetaTitle = updatedOrder.taskMetaData.api_output.new_meta_title_description.meta_title;
          const newMetaDescription = updatedOrder.taskMetaData.api_output.new_meta_title_description.meta_description;

          this.$set(this.order.json_data.api_input.page_url[0], 'page_title', newMetaTitle);
          this.$set(this.order.json_data.api_input.page_url[0], 'page_description', newMetaDescription);

          this.form.meta_title_description.meta_title = newMetaTitle;
          this.form.meta_title_description.meta_description = newMetaDescription;
        }
        this.$preloader.fadeOut();
      } catch (error) {
        console.error("Error creating metadata", error);
      }
    },

    async optimizeTaskMetaData() {
      try {
        this.$preloader.fadeIn();
        const updatedOrder = await this.$store.dispatch("optimizeTaskMetaData", this.form);
        if (updatedOrder && updatedOrder.taskMetaData && updatedOrder.taskMetaData.api_output && updatedOrder.taskMetaData.api_output.new_meta_title_description) {
          const newMetaTitle = updatedOrder.taskMetaData.api_output.new_meta_title_description.meta_title;
          const newMetaDescription = updatedOrder.taskMetaData.api_output.new_meta_title_description.meta_description;

          this.$set(this.order.json_data.api_input.page_url[0], 'page_title', newMetaTitle);
          this.$set(this.order.json_data.api_input.page_url[0], 'page_description', newMetaDescription);

          this.form.meta_title_description.meta_title = newMetaTitle;
          this.form.meta_title_description.meta_description = newMetaDescription;
        }
        this.$preloader.fadeOut();
      } catch (error) {
        console.error("Error optimizing metadata", error);
      }
    },

    async saveTaskMetaData() {
      try {
        this.$preloader.fadeIn();
        await this.$store.dispatch("saveTaskMetaData", this.form);
        this.$preloader.fadeOut();
      } catch (error) {
        console.error("Error saving metadata", error);
      }
    },
    async setEnableMetaData(value) {
      try {
        if(this.user.role == 'user') {
          await this.$store.dispatch("toggleMetaData", {
          toggle_metadata: value,
          order_detail_id: this.order?.order_detail_id,
        });
        }
      } catch (error) {
        console.error("Error saving metadata", error);
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  }
};
</script>

<style>
input::placeholder {
  color: #525f7f !important;
}
</style>
